<template>
  <independent-loader v-if="!entities_loaded"></independent-loader>
  <div>
    <div class="row mt-1">
      <div
        class="col-md-4 col-sm-6 col-12 mt-6"
        v-for="entity in entities"
        :key="entity.uuid"
      >
        <router-link
          :to="{ name:'Company Profile', params: { entity_name: entity.search_name,name:entity.name.split(' ').join('_') } }"
        >
          <div class="card card-profile">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      :src="backend_url + entity.logo"
                      class="rounded-circle shadow-lg bg-white"
                      :onerror="`this.src='${$img_placeholder}';`"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-5 pt-md-4 pb-0 pb-md-4"
            ></div>

            <div class="card-body pt-0">
              <h2 class="h3 mt-5 text-center">{{ entity.name }}</h2>
              <div class="text-center">
                <!-- <div class="h5 font-weight-300">
                        <em class="fas fa-location-arrow mr-2"></em>
                        {{ entity.address }}
                      </div> -->
                <div class="h5 font-weight-300">
                  <!-- <em class="fas fa-phone mr-2"></em> -->
                  <span v-if="entity.industry">{{ entity.industry.name }}</span>
                  <span v-else> {{ entity.other_industry || "" }} </span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import IndependentLoader from "../components/IndependentDefaultLoader";
import axios from "axios";
export default {
  components: {
    // Loader
    "independent-loader": IndependentLoader,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  // watch: {
  //   uuid: function () {
  //     this.fetch_entities_in_industry();
  //   },
  // },
  data() {
    return {
      selected: "all_active",
      entities: [],
      entities_loaded: false,
      backend_url: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    fetch_entities_in_industry: function () {
      this.entities_loaded = false;
      if (this.$route.params.uuid == "") {
        // first we check if no industry selected to display all
        this.fetch_approved_entities();
      } else {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_URL}/api/industries/${this.uuid}/entities`
          )
          .then((res) => {
            res.data.sort(function () {
              return 0.5 - Math.random();
            });
            this.entities = res.data.slice(0, 6);
            this.entities_loaded = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    fetch_approved_entities: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/Approved/public/`;
      axios
        .get(url)
        .then((res) => {
          res.data.sort(function () {
            return 0.5 - Math.random();
          });
          this.entities = res.data.slice(0, 6);
          this.entities_loaded = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.fetch_entities_in_industry();
  },
};
</script>
<style lang="">
</style>